.about-section h1 {
  margin-bottom: 4rem;
}

.about-head-underline {
  width: 12rem;
  position: absolute;
  top: 5rem;
  z-index: 0;
}

.about-content-mobile {
  display: flex;
  justify-content: space-evenly;
}

.about-grid-mobile {
  display: grid;
  gap: 2rem;
}

.about-content-desktop {
  display: none;
}

.about-grid-left,
.about-grid-right {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
}

.about-grid-left {
  grid-template-areas:
    "item-1"
    "."
    "item-3"
    "."
    "item-5"
    "."
    "item-7";
}

.about-grid-right {
  grid-template-areas:
  "."
  "item-2"
  "."
  "item-4"
  "."
  "item-6";
}

.fun-facts {
  height: 40vh;
}

@media (min-width:600px)  {
  .about-head-underline {
    top: 5rem;
    left: 4rem;
  }
}

@media (min-width: 800px) {
  .about-head-underline {
    width: 14rem;
    top: 6rem;
  }

  .about-content-mobile {
    display: none;
  }

  .about-content-desktop {
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    margin: 0 auto;
  }
}
