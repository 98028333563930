.certs-section {
  margin-top: 6rem;
}

.certs-section h2 {
  font-family: "Permanent Marker";
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

.certs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  max-width: 64rem;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .certs-section h2 {
    margin-left: 4rem;
    font-size: 3rem;
  }

  .certs-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
