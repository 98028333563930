@import "../styles/variables.css";

.mobile-nav {
  position: relative;
}

.mobile-navbar {
  position: relative;
}

.desk-navbar {
  display: none;
}

.active-navbar {
  border-bottom: 4px solid var(--yellow);
  padding-bottom: 0.2rem;
}

.inactive-navbar {
  border-bottom: 4px solid white;
  padding-bottom: 0.2rem;
}

.hamburger {
  all: unset;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 90;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 1.5rem;
  height: 0.2rem;
  top: 0;
  left: 0;
  background: var(--yellow);
  border-radius: 8px;
  transform: rotate(0);
}

.hamburger-middle {
  transform: translateY(0.5rem);
}

.hamburger-bottom {
  transform: translateY(1rem);
}

.mobile-menu-container {
  position: fixed;
  width: 100vw;
  height: 150vh;
  border-bottom-left-radius: 400px;
  background: var(--orange);
  z-index: 50;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  margin: 6rem;
  font-family: "Permanent Marker";
  color: white;
  font-size: 2rem;
  gap: 1rem;
}

@media (min-width: 600px) {
  .mobile-nav {
    display: none;
  }

  .desk-navbar {
    top: 0;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .desk-navbar-list {
    font-family: "Permanent Marker";
    font-size: 1.5rem;
    display: flex;
    gap: 2rem;
  }

  .desk-navbar-icon {
    font-size: 1.5rem;
  }
}
