.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 95;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 50rem;
  max-height: 80%;
  background-color: rgb(240, 240, 240, 1);
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 96;
  box-sizing: border-box;
  padding: 2rem 2rem;
  overflow: scroll;
}

@media (min-width: 800px) {
  .modal {
    padding: 3rem 4rem;
  }
}
