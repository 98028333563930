.project-image-portrait {
  overflow: hidden;
  max-width: 10rem;
  text-align: center;
}

.project-image-landscape {
  overflow: hidden;
  max-width: 15rem;
  text-align: center;
}

.project-image_carousel-portrait {
  height: 20.5rem;
  width: 10rem;
  line-height: 0;
  overflow: hidden;
}

.project-image_carousel-landscape {
  height: 22.5rem;
  width: 15rem;
  line-height: 0;
  overflow: hidden;
}

@media (min-width: 800px) {
  .project-image-portrait {
    max-width: 15rem;
  }

  .project-image_carousel-portrait {
    height: 30.9rem;
    width: 15rem;
  }
}
