footer {
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
  width: 8rem;
  font-size: 2rem;
  color: var(--yellow);
}
