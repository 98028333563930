.languages-section {
  height: 60vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.languages-top-border,
.languages-bottom-border {
  position: absolute;
  height: 4px;
  width: 100%;
  background: var(--yellow);
}

.languages-top-border {
  top: 0;
  animation: lineDrawToRight 1s ease-out;
}

.languages-bottom-border {
  bottom: 0;
  animation: lineDrawToLeft 1s ease-out;
}

.languages-list {
  font-family: "Permanent Marker";
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes lineDrawToRight {
  0% {
    right: 100%;
    width: 0;
  }
  100% {
    right: 0;
    width: 100%;
  }
}

@keyframes lineDrawToLeft {
  0% {
    left: 100%;
    width:0;
  }
  100% {
    left: 0;
    width: 100%;
  }
}

@media (min-width: 600px) {
  .languages-section {
    height: 50vh;
  }
}
