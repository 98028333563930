.certificate-container {
  position: relative;
  height: 20rem;
}

.certificate-header {
  font-family: "Permanent Marker";
}

.certificate-info {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  bottom: 30%;
  left: 5%;
  width: 100%;
}

.certificate-info-type {
  font-style: italic;
}

.certificate-provider {
  margin-bottom: 1rem;
}

.certificate-image-container {
  position: absolute;
  bottom: 10%;
  font-family: "Permanent Marker";
  font-size: 0.8rem;
  color: var(--orange);
}

.certificate-bottom-border {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  margin: 0 auto;
  background-color: var(--yellow);
}

.certificate-link:hover {
  cursor: pointer;
}

.certificate-image {
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 48rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

@media (min-width: 800px) {
  .certificate-container {
    position: relative;
    height: 12rem;
  }

  .certificate-info {
    left: 0%;
    grid-template-columns: 1fr 1fr;
  }

  .certificate-provider {
    margin-bottom: 0;
  }
}
