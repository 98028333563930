a {
  text-decoration: none;
  color: inherit;
}

.page-header-section {
  position: relative;
}

.page-header {
  font-family: "Permanent Marker";
  font-size: 4rem;
}

.page-section {
  padding: 2rem 1rem;
  margin-bottom: 4rem;
}

@media (min-width:600px)  {
  .page-header {
    margin-top: 2rem;
    margin-left: 4rem;
  }
}

@media (min-width: 800px) {
  .page-header {
    font-size: 5rem;
  }

  .page-section {
    padding: 2rem;
  }
}

.draw-on-load {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-in-out forwards;
  animation-delay: 1s;
}

.draw-on-load-delay {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-in-out forwards;
  animation-delay: 1.5s;
}

.draw-on-load-mid-delay {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-in-out forwards;
  animation-delay: 2s;
}

.draw-on-load-long-delay {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-in-out forwards;
  animation-delay: 3s;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
