.fun-facts-section {
  margin: 8rem 0;
  position: relative;
}

.fun-facts-section h2 {
  font-family: "Permanent Marker";
  font-size: 2.5rem;
  margin-left: 1rem;
  margin-bottom: 4rem;
}

.fun-facts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  position: relative;
  z-index: 10;
  margin: 0 2rem;
}

.fun-facts-line1 {
  height: 1rem;
  width: 110%;
  background: var(--orange);
  position: absolute;
  top: 37%;
  z-index: 0;
}

.fun-facts-line2 {
  height: 1rem;
  width: 100%;
  background: var(--orange);
  position: absolute;
  top: 80%;
  z-index: 0;
}

@media (min-width: 800px) {
  .fun-facts {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 56rem;
    margin: 0 auto;
  }

  .fun-facts-section h2 {
    margin-left: 6rem;
    font-size: 3rem;
  }

  .fun-facts-line1 {
    top: 65%;
  }

  .fun-facts-line2 {
    display: none;
  }
}
