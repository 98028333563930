.project-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  max-width: 32rem;
  margin: 0 auto;
  positon: relative;
  padding-bottom: 4rem;
  padding-top: 4rem;
  border-bottom: 2px solid var(--yellow);
}

.project-header {
  font-family: "Permanent Marker";
  font-size: 1.2rem;
  margin: 0;
}

.project-header:hover {
  cursor: pointer;
}

/* .project-img-container {
  overflow: hidden;
} */

.project-img {
  width: 12rem;
  filter: saturate(80%);
}

.project-img:hover {
  filter: saturate(100%);
  scale: 1.05;
  transition: all 0.5s ease;
  cursor: pointer;
}

.project-left {
  direction: rtl;
}

.project-left-text {
  text-align: right;
}

.project-left-image {
  justify-self: end;
}

.project-right-image {
  justify-self: end;
}

.project-right-text {
  justify-self: start;
}

.project-links {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 4rem;
}

@media (min-width: 800px) {
  .project-container  {
    margin-bottom: 0;
    max-width: 48rem;

  }

  .project-header {
    font-size: 1.2rem;
  }
}

@media (min-width: 1200px) {
  .project-header {
    font-size: 2rem;
  }

  .project-img-container img {
    width: 16rem;
  }
}
