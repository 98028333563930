.stack-head-underline {
  width: 11rem;
  position: absolute;
  top: 5rem;
  z-index: 0;
}

@media (min-width:600px)  {
  .stack-head-underline {
    top: 5rem;
    left: 4rem;
  }
}

@media (min-width: 800px) {
  .stack-head-underline {
    width: 14rem;
    top: 6rem;
    left: 4rem;
  }
}
