.about-box {
  max-width: 24rem;
  padding: 2rem;
  border: 2px var(--orange) solid;
  border-radius: 4px;
  background-color: rgb(245, 138, 7, 0.05);
  position: relative;
}

.about-box-marker {
  position: absolute;
  top: 90%;
}

.about-box h2 {
  margin-top: 0;
  font-family: "Permanent Marker";
  color: var(--orange);
}

@media (min-width: 1000px) {
  .about-box-marker {
    top: 130%;
  }
}
