.project-header {
  font-family: "Permanent Marker";
  font-size: 4rem;
  text-align: center;
}

.project-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  max-width: 32rem;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  border-top: 2px solid var(--yellow);
  border-bottom: 2px solid var(--yellow);
}

.project-info-text p:first-child {
  margin-top: 0;
}

.project-languages {
  margin-bottom: 2rem;
}

.project-languages h5 {
  font-family: "Permanent Marker";
  font-size: 1.5rem;
  margin-bottom: 0;
}

.project-links {
  width: 4rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.project-images-container-portrait {
  width: 10rem;
}

.project-images-container-landscape {
  width: 15rem;
}

.project-back-button {
  all: unset;
  font-family: "Permanent Marker";
  margin-top: 2rem;
}

@media (min-width:600px)  {
  .project-header {
    margin-top: 2rem;
  }

  .project-back-button {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    margin-top: 0;
  }
}

@media (min-width: 800px) {
  .project-header {
    font-size: 5rem;
  }

  .project-images-container-portrait {
    width: 15rem;
  }

  .project-images-container-landscape {
    width: 15rem;
  }
}

@media (min-width: 1100px) {
  .project-details {
    display: flex;
    flex-direction: row;
    gap: 6vw;
    max-width: 56rem;
  }
}
