.project-head-underline {
  width: 19rem;
  position: absolute;
  top: 5rem;
  z-index: 0;
}

@media (min-width:600px)  {
  .project-head-underline {
    top: 5rem;
    left: 4rem;
  }
}

@media (min-width: 800px) {
  .project-head-underline {
    width: 23rem;
    top: 6rem;
    left: 4rem;
  }

  .project-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 1000px) {
  .project-grid {
    grid-column-gap: 2rem;
  }
}
