
.languages-list-item {
  position: absolute;
}

.languages-list-javascript {
  top: 38%;
  left: 15%;
  font-size: 3rem;
  color: var(--orange);
}

.languages-list-html {
  top: 55%;
  left: 5%;
  font-size: 2.5rem;
}

.languages-list-css {
  top: 60%;
  left: 75%;
  font-size: 2.5rem;
  color: var(--orange);
}

.languages-list-tailwind {
  top: 87%;
  left: 0%;
  font-size: 2rem;
  color: var(--orange);
}

.languages-list-ruby {
  top: 17%;
  left: 0%;
  font-size: 2.5rem;
  color: var(--yellow);
}

.languages-list-react {
  top: 25%;
  left: 40%;
  font-size: 2.5rem;
  color: var(--yellow);
}

.languages-list-bootstrap {
  top: 85%;
  left: 60%;
  font-size: 1.5rem;
  color: var(--yellow);
}

.languages-list-typescript {
  top: 12%;
  left: 50%;
  font-size: 2rem;
}

.languages-list-sql {
  top: 3%;
  left: 40%;
  font-size: 1.5rem;
}

.languages-list-heroku {
  top: 78%;
  left: 25%;
  font-size: 1.5rem;
}

.languages-list-github {
  top: 68%;
  left: 35%;
  font-size: 2rem;
}

.languages-list-figma {
  top: 5%;
  left: 5%;
  font-size: 2rem;
}

@media (min-width: 600px) and (max-width: 1000px) {
  .languages-list-javascript {
    top: 35%;
    left: 35%;
    font-size: 3rem;
    color: var(--orange);
  }

  .languages-list-html {
    top: 50%;
    left: 15%;
    font-size: 2.5rem;
  }

  .languages-list-css {
    top: 50%;
    left: 80%;
    font-size: 2.5rem;
    color: var(--orange);
  }

  .languages-list-tailwind {
    top: 80%;
    left: 5%;
    font-size: 2rem;
    color: var(--orange);
  }

  .languages-list-ruby {
    top: 15%;
    left: 20%;
    font-size: 2.5rem;
    color: var(--yellow);
  }

  .languages-list-react {
    top: 20%;
    left: 60%;
    font-size: 2.5rem;
    color: var(--yellow);
  }

  .languages-list-bootstrap {
    top: 80%;
    left: 75%;
    font-size: 1.5rem;
    color: var(--yellow);
  }

  .languages-list-typescript {
    top: 5%;
    left: 70%;
    font-size: 2.5rem;
  }

  .languages-list-sql {
    top: 5%;
    left: 35%;
    font-size: 1.5rem;
  }

  .languages-list-heroku {
    top: 70%;
    left: 35%;
    font-size: 1.5rem;
  }

  .languages-list-github {
    top: 60%;
    left: 60%;
    font-size: 2rem;
  }

  .languages-list-figma {
    top: 7%;
    left: 5%;
    font-size: 2rem;
  }
}

@media (min-width: 1000px) {
  .languages-list-javascript {
    top: 35%;
    left: 40%;
    font-size: 3.5rem;
    color: var(--orange);
  }

  .languages-list-html {
    top: 50%;
    left: 15%;
    font-size: 3rem;
  }

  .languages-list-css {
    top: 50%;
    left: 80%;
    font-size: 3rem;
    color: var(--orange);
  }

  .languages-list-tailwind {
    top: 80%;
    left: 5%;
    font-size: 2.5rem;
    color: var(--orange);
  }

  .languages-list-ruby {
    top: 15%;
    left: 20%;
    font-size: 3rem;
    color: var(--yellow);
  }

  .languages-list-react {
    top: 15%;
    left: 53%;
    font-size: 3rem;
    color: var(--yellow);
  }

  .languages-list-bootstrap {
    top: 80%;
    left: 75%;
    font-size: 1.5rem;
    color: var(--yellow);
  }

  .languages-list-typescript {
    top: 10%;
    left: 70%;
    font-size: 3rem;
  }

  .languages-list-sql {
    top: 5%;
    left: 35%;
    font-size: 2rem;
  }

  .languages-list-heroku {
    top: 70%;
    left: 35%;
    font-size: 2rem;
  }

  .languages-list-github {
    top: 60%;
    left: 60%;
    font-size: 2.5rem;
  }

  .languages-list-figma {
    top: 7%;
    left: 7%;
    font-size: 2rem;
  }
}
